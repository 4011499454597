 <template>
  <div class="uk-container">
    <v-row class="uk-container uk-margin-large-top">
      <v-col cols="12">
        <h1 class="font-weight-bold" :style="{ color: this.fontWeightBold }">
          <base-arrow-back-icon @click="goTwoSteps ? $router.go(-3) : $router.go(-1)">{{
            rightArrowIcon
          }}</base-arrow-back-icon>

          الدروس
        </h1>
      </v-col>
      <v-col cols="12">
        <table-actions-nav
          type="lecture"
          v-model="search"
          :data="export_things"
          :options="{ withAddNew: true, withSearch: true, withExport: true }"
        ></table-actions-nav>
      </v-col>
    </v-row>
    <v-row class="uk-margin-small-top uk-margin-large-bottom">
      <template>
        <v-data-table
          :headers="lectureHeaders"
          :items="lectures"
          :items-per-page="5"
          :search="search"
          @click:row="openLect"
          class="elevation-1 w-100"
          :footer-props="{
            itemsPerPageText: 'عدد الأسطر في الجدول:',
          }"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <table-actions
              @remove="remove(item.id)"
              @show="show(item.id)"
              @edit="edit(item.id)"
            ></table-actions>
          </template>
        </v-data-table>
      </template>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import TableActionsNav from "../../Components/TableActionsNav";
import TableActions from "../../Components/TableActions";
import BaseArrowBackIcon from "@/core/Base/Arrows/BaseArrowBackIcon";
export default {
  components: {
    TableActions,
    TableActionsNav,
    BaseArrowBackIcon,
  },
  data() {
    return {
      search: "",
      lectureHeaders: [
        {
          text: "العنوان",
          align: "center",
          sortable: false,
          value: "title",
        },
        {
          text: "الشرح",
          align: "center",
          sortable: false,
          value: "description",
        },
        { text: "", value: "actions", align: "center" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      lectures: "getLectures",
    }),
    ...mapGetters("Courses/Modules", ["getSingleData"]),
    goTwoSteps () {
      if (this.$route.query.fromWhere === 'addEdit') {
        return true;
      } else {
        return false;
      }
    },
    export_things() {
      return {
        name: "Lectures",
        fields: {
          "اسم الدرس": "title",
          "لشرح ": "description",
        },
        data: this.lectures,
      };
    },
  },
  mounted() {
    this.fetchLectures(this.$route.params.id);
  },
  methods: {
    ...mapActions(["fetchLectures", "deleteLectureInstructor"]),
    ...mapActions("Courses/Modules", ["fetchSingleData"]),

    openLect (payload) {
      console.log(payload)
      this.fetchSingleData({ id: payload.id, type: 'lecture' }).then(() => {
        console.log(this.getSingleData)
        window.open('https://bact-back.usol.ca/' + this.getSingleData.content_url, '_blank');
      })
    },
    remove(id) {
      this.deleteLectureInstructor(id).then(() => {
        this.fetchLectures(this.$route.params.id);
      });
    },
    show(id) {
      this.$router.push({ name: "showLecture", params: { id: id } });
    },
    edit(id) {
      this.$router.push({ name: "editLecture", params: { id: id } });
    },
  },
};
</script>

<style scoped>
.v-data-table
  /deep/
  tbody
  /deep/
  tr:hover {
    cursor: pointer;
    background: lightgray !important;
}
</style>